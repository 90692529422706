@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
.errmsg {
    background-color: lightpink;
    color: firebrick;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}
.offscreen {
    position: absolute;
    left: -9999px;
}

.hide {
    display: none;
}
.id-card-button {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
    transition: all 0.3s ease-in-out;
}

.id-card-button:hover {
    background-color: #ccc;
    color: #fff;
}